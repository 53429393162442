import i18next from "i18next";




i18next.init({
    interpolation: {
        escapeValue: false
    },
    lng: 'en'
});

export default i18next;
