import React, {Component, Fragment} from 'react'
import FadeInBlocks from './FadeInBlocks'
// import Link from "react-router-dom/modules/Link";
import {NavLink} from "react-router-dom";
import global from "../data/global"
import data from "../data/home";

class CityBlock extends Component {
    render() {
        const {cityData, locale} = this.props;
        return (
            <div className='container-fluid red-banner'>
                {/*<div className='row'>*/}
                    <div className="col-md-12">
                        <div className="row">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2 className="my-title"
                                            dangerouslySetInnerHTML={{__html: data.data.red_banner[locale]}}/>
                                    </div>
                                </div>
                                <div className="row">
                                    {cityData && cityData.map((item, index) =>
                                        <FadeInBlocks key={index} delay={300}>
                                            <NavLink to={`${item.slug}?lang=${locale}`}>
                                                <div className="my-card">
                                                    <div style={{backgroundImage: `url(${item.small_image})`}}
                                                         className="my-card-img"/>
                                                    <div className="my-card-title">
                                                        <h4>{item.title[locale]}</h4>
                                                        {/*<NavLink className='my-card-innerButton' to={`${item.slug}?lang=${locale}`}>*/}
                                                        <span className='my-card-innerButton'>
                                                        <img src="/img/icons/arrow_right.svg" alt=""/>
                                                    </span>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </FadeInBlocks>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                {/*</div>*/}
            </div>
        )
    }
}

export default CityBlock;
