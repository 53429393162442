import React from 'react';

import Swiper from "react-id-swiper";

class Slider extends React.Component{
    constructor(props) {
        super(props)
        this.swiperMain = null;
    }
    swiperAutoplay = (props) => {

        this.swiperMain = props;
        const {swiperMain} = this;

        if (swiperMain !== null) {
            swiperMain.init();
        }
    };
    render(){
        const {data} = this.props
        const params = {
            fadeEffect: {crossFade: true},
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
            loop: true,
            speed: 0,
            slidersPerView: 1,
            allowTouchMove: false,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            getSwiper: this.swiperAutoplay,
            on: {
                init: function () {
                    document.querySelector('.home-slider').style.opacity = 1
                },
            }
        };
        return (
            <Swiper {...params}>
                {data.images.map((item, idx) => (
                    <div key={idx}>
                        <div style={{backgroundImage: `url(${item.img})`}}/>
                    </div>
                ))}
            </Swiper>
        )
    }
};
export default Slider;
