import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import Favicon from "react-favicon";
import TagManager from 'react-gtm-module'
import App from "./App";
import createStore from "./store";
// import * as serviceWorker  from './serviceWorker';
const tagManagerArgs = {
    gtmId: 'G-FJH54QWK2K'
}
TagManager.initialize(tagManagerArgs);

const store = createStore(window.REDUX_DATA);
const jsx = (
  <ReduxProvider store={store}>
    <Favicon url="/img/favicon.png" />
    <Router>
      <App />
    </Router>
  </ReduxProvider>
);

const app = document.getElementById("root");
ReactDOM.hydrate(jsx, app);
// serviceWorker.register();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
