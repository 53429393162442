import Home from "./views/Home";
import About from "./views/About";
import Contact from "./views/Contact";
import ActivityInner from "./views/ActivityInner";
import CityInner from "./views/CityInner";
import NotFound from "./views/NotFound";
import Privacy from "./views/Privacy";
import Terms from "./views/Terms";

const Routes = [
  {
    path: "/who_are_we",
    exact: true,
    component: About,
  },
  {
    path: "/",
    exact: true,
    component: Home,
  },
  {
    path: "/get_in_touch",
    exact: true,
    component: Contact,
  },
  {
    path: "/:slag/:slug",
    component: ActivityInner,
  },
  {
    path: "/privacy_policy",
    exact: true,
    component: Privacy,
  },
  {
    path: "/terms",
    exact: true,
    component: Terms,
  },
  {
    path: "/:slag",
    component: CityInner,
  },
  {
    component: NotFound,
  },
];

export default Routes;
