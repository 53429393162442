import {createStore, combineReducers, applyMiddleware} from "redux";
import thunkMiddleware from "redux-thunk";
import {loadCityData, loadData, loadActivityData} from "./helpers/loadData";
import {loadDataAbout} from "./helpers/loadDataAbout";
import {loadDataContact} from "./helpers/loadDataContact";

export const initializeSession = () => ({
    type: "INITIALIZE_SESSION",
});

const storeData = (data) => ({
    type: "STORE_DATA",
    data,
});

const storeCityData = (data) => ({
    type: "STORE_CITY_DATA",
    data,
});

const storeActivityData = (data) => ({
    type: "STORE_ACTIVITY_DATA",
    data,
});


const storeDataAbout = (data) => ({
    type: "STORE_ABOUT",
    data,
});
const storeDataContact = (data) => ({
    type: "STORE_CONTACT",
    data,
});

export const fetchData = () => async dispatch => {
    const res = await loadData();
    dispatch(storeData(res));
    return res;
};

export const fetchCityData = (slug) => async dispatch => {
    const res = await loadCityData(slug);
    dispatch(storeCityData(res));
    return res;
};

export const fetchActivityData = (slug) => async dispatch => {
    const res = await loadActivityData(slug);
    dispatch(storeActivityData(res));
    return res;
};

export const fetchDataAbout = () => async dispatch => {
    const res = await loadDataAbout();
    dispatch(storeDataAbout(res));
    return res;
};

export const fetchDataContact = () => async dispatch => {
    const res = await loadDataContact();
    dispatch(storeDataContact(res));
    return res;
};
const sessionReducer = (state = false, action) => {
    switch (action.type) {
        case "INITIALIZE_SESSION":
            return true;
        default:
            return state;
    }
};

const dataReducer = (state = [], action) => {
    switch (action.type) {
        case "STORE_DATA":
            return action.data;
        default:
            return state;
    }
};

const dataReducerCity = (state = [], action) => {
    switch (action.type) {
        case "STORE_CITY_DATA":
            return action.data;
        default:
            return state;
    }
};

const dataReducerActivity = (state = [], action) => {
    switch (action.type) {
        case "STORE_ACTIVITY_DATA":
            return action.data;
        default:
            return state;
    }
};

const dataReducerAbout = (state = [], action) => {
    switch (action.type) {
        case "STORE_ABOUT":
            return action.data;
        default:
            return state;
    }
};


const dataReducerContact = (state = [], action) => {
    switch (action.type) {
        case "STORE_CONTACT":
            return action.data;
        default:
            return state;
    }
};

const reducer = combineReducers({
    loggedIn: sessionReducer,
    cityList: dataReducer,
    activity: dataReducerActivity,
    about: dataReducerAbout,
    contact: dataReducerContact,
    cityData: dataReducerCity
});

export default (initialState) =>
    createStore(reducer, initialState, applyMiddleware(thunkMiddleware));
