import React, { useState } from "react";
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
  AvField,
} from "availity-reactstrap-validation";
import { Label, Button } from "reactstrap";
import global from "../data/global";
import axios from "axios";
import { BtnLoader } from "./BtnLoaders";

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      showMsg: false,
      isSuccess: false,
      disableBtn: false,
    };
  }

  componentDidMount() {
    this.setState({
      showMsg: false,
    });
  }

  onChange = (e) => {
    console.log(e.target.innerHTML);
    this.setState({
      message: e.target.innerHTML,
    });
  };

  onValidSubmith = (event, values) => {
    this.setState({ disableBtn: true });
    console.log(values.personal);
    axios
      .post("https://api.littlearmenias.com/api/sendMail", values.personal)
      .then((res) => {
        this.setState({
          isSuccess: true,
          showMsg: true,
          disableBtn: false,
        });
        // setTimeout(() => {
        //   this.setState({ showMsg: false });
        // }, 5000);
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          isSuccess: false,
          showMsg: true,
          disableBtn: false,
        });
        // setTimeout(() => {
        //   this.setState({ showMsg: false });
        // }, 5000);
      });
  };

  render() {
    const { isPhone, locale } = this.props;
    const { message, showMsg, isSuccess, disableBtn } = this.state;
    return (
      <div>
        <AvForm
          onValidSubmit={this.onValidSubmith}
          onInvalidSubmit={this.onInvalidSubmith}
          className="my-form"
          ref="userForm"
        >
          <div className="row">
            <div className="col-md-6">
              <AvGroup>
                <AvInput
                  id="fieldfirstname"
                  name="personal.name"
                  required
                  tabIndex={1}
                />
                <Label className="form-control-label" for="fieldfirstname">
                  {global.name[locale]} *
                </Label>
                <AvFeedback>{global.required_field[locale]}</AvFeedback>
              </AvGroup>
            </div>
            <div className="col-md-6">
              <AvGroup>
                <AvInput
                  id="fieldlastname"
                  name="personal.surname"
                  required
                  tabIndex={2}
                />
                <Label className="form-control-label" for="fieldlastname">
                  {global.surname[locale]} *
                </Label>
                <AvFeedback>{global.required_field[locale]}</AvFeedback>
              </AvGroup>
            </div>

            <div className="col-md-6">
              <AvGroup>
                <AvInput
                  type="email"
                  id="fieldemail"
                  name="personal.email"
                  required
                  tabIndex={3}
                  // label={`${global.email[locale]} *`}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter an email",
                    },
                    email: {
                      value: true,
                      errorMessage: "This is not a valid email address",
                    },
                  }}
                />
                <Label className="form-control-label" for="fieldemail">
                  {global.email[locale]} *
                </Label>
                <AvFeedback>{global.required_field[locale]}</AvFeedback>
              </AvGroup>
            </div>

            <div className="col-md-6">
              <AvGroup>
                <AvInput
                  id="fieldphone"
                  name="personal.phone"
                  tabIndex={4}
                />
                <Label className="form-control-label" for="fieldphone">
                  {global.phone[locale]}
                </Label>
                <AvFeedback>{global.required_field[locale]}</AvFeedback>
              </AvGroup>
            </div>

            <div className="col-md-12">
              <AvGroup>
                <AvInput
                  type="textarea"
                  name="personal.message"
                  id="textarea"
                  required
                  className={message !== "" ? "notEmpty" : "empty"}
                  tabIndex={5}
                  onBlur={(e) => this.onChange(e)}
                />
                <Label className="form-control-label" for="textarea">
                  {global.message[locale]} *
                </Label>
                <AvFeedback>{global.required_field[locale]}</AvFeedback>
              </AvGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Button
                type="submit"
                id="my-btn"
                className="my-btn orange"
                color="primary"
                disabled={disableBtn}
              >
                {disableBtn ? <BtnLoader /> : global.send[locale]}
              </Button>
            </div>
          </div>
        </AvForm>
        <div className={`thank-you-msg ${showMsg ? "show" : ""}`}>
          {isSuccess
            ? global.thank_you_success[locale]
            : global.thank_you_fail[locale]}
        </div>
      </div>
    );
  }
}

export default Form;
