import React, {Fragment} from "react";
import {fetchActivityData} from "../store";
import {connect} from "react-redux";
import StarRatings from "react-star-ratings";
import global from "../data/global";
import Meta from "../components/Meta";
import Form from "../components/Form";
import Gallery from "../components/Gallery";
import PageLoader from "../components/PageLoader";

class ActivityInner extends React.Component {
    state = {
        loading: true,
    };

    componentDidMount() {
        this.props.fetchActivityData(this.props.match.params.slug).then((res) => {
            this.setState({
                loading: false,
            });
        });
        window.scrollTo(0, 0);
    }

    render() {
        const {loading} = this.state;
        const {activityData, locale} = this.props;
        // const currentCity =
        //     cityList &&
        //     cityList.filter((obj) => {
        //         return obj.slug === this.props.match.params.slag;
        //     });
        // const currentActivity =
        //     currentCity &&
        //     currentCity[0] &&
        //     currentCity[0].activities.filter((obj) => {
        //         return obj.slug === this.props.match.params.slug;
        //     });
        // console.log(currentActivity);
        return (
            <div>
                <Fragment>
                    <PageLoader show={loading}/>
                    <Meta
                        title={activityData?.title?.[locale]}
                        desc={activityData?.description?.[locale]}
                        img={activityData?.image}
                        url={this.props.location.pathname}
                    />
                    <div className="container mb-50">
                        <div className="activityInner-banner row">
                            <div className="col-md-12 pt-4">
                                {activityData?.title?.[locale] && (
                                    <div className="row">
                                        <h2 className="my-underline mobile-el my-title">
                                            {activityData.title[locale]}
                                        </h2>
                                    </div>
                                )}
                                <div className="row col-md-12">
                                    <h2 className="my-underline desktop-el my-title">
                                        {activityData?.title?.[locale]}
                                    </h2>
                                </div>
                                <div
                                    className="activityInner-banner-img float-left"
                                    style={{
                                        backgroundImage: `url(${activityData?.image})`,
                                    }}
                                />
                                <div className="activityInner-banner-info">
                                    {activityData?.address?.[locale] && (
                                        <>
                                            <div className="row activity-points mt-md-0">
                                                <div className="activity-points-item">
                                                    <i className="fas fa-map-marker-alt"/>
                                                    <p>
                                                        <strong>{global.address[locale]}:</strong>
                                                        <br/>
                                                        <span>
                                                            {activityData.address[locale]}
                                                          </span>
                                                    </p>
                                                </div>


                                                {/* <div className="activity-points-item">
                            <i className="fas fa-clock" />
                            <p>
                              <strong>{global.duration[locale]}:</strong>
                              <br />
                              <span>
                                {
                                  currentActivity?.[0].point_info[1].duration[
                                    locale
                                  ]
                                }
                              </span>
                            </p>
                          </div> */}
                                                {/*<div className="col-md-3">*/}
                                                {/*<div className="activity-points-item">*/}
                                                {/*<i className="fas fa-envelope"/>*/}
                                                {/*<p>*/}
                                                {/*<strong>*/}
                                                {/*{global.contact[locale]}*/}
                                                {/*</strong>*/}
                                                {/*<br/>*/}
                                                {/*<span>*/}
                                                {/*{currentActivity?.[0].point_info[2].contact[locale]}*/}
                                                {/*</span>*/}
                                                {/*</p>*/}
                                                {/*</div>*/}
                                                {/*</div>*/}
                                                {/* <div className="activity-points-item">
                            <i className="fas fa-dollar-sign" />
                            <p>
                              <strong>{global.price[locale]}:</strong>
                              <br />
                              <span>
                                {currentActivity?.[0].point_info[3].price}
                              </span>
                            </p>
                          </div> */}
                                            </div>
                                            <p
                                                className="desc"
                                                dangerouslySetInnerHTML={{
                                                    __html: activityData?.description?.[locale],
                                                }}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {activityData?.gallery?.[0] && (
                        <Gallery
                            data={activityData.gallery}
                            locale={locale}
                        />
                    )}


                    {/* {currentActivity?.[0].testimonials && (
            <div className="container">
              <div className="row">
                <div className="col-md-12 mt-30 mb-3">
                  <h2 className="my-underline my-title">Testimonials</h2>
                </div>
              </div>

              {currentActivity?.[0].testimonials.map((item, id) => {
                return (
                  <div className="row testimonials mt-30" key={id}>
                    <div className="img-block">
                      <img src={item.image} alt="" />
                      <p className="mt-1">
                        <strong>{item.name[locale]}</strong>
                      </p>
                    </div>
                    <div className="info-block">
                      <StarRatings
                        rating={item.stars}
                        starRatedColor="#F0A002"
                        numberOfStars={5}
                        name="rating"
                        starDimension="20px"
                        starSpacing="2.5px"
                      />
                      <p className="desc mt-1">{item.about[locale]}</p>
                      <p>
                        <strong>{item.city_name[locale]}</strong>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          )} */}


                    <div className="container mb-5 mt-100">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="my-underline my-title">Contact form</h2>
                            </div>
                            <div className="col-md-8">
                                <Form isPhone={true} locale={locale}/>
                            </div>
                        </div>
                    </div>
                </Fragment>
            </div>
        );
    }
}

ActivityInner.serverFetch = fetchActivityData;

const mapStateToProps = (state) => ({
    activityData: state.activity.data,
});

const mapDispatchToProps = {
    fetchActivityData,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityInner);
