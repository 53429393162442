import React, {Fragment} from "react";
import {connect} from "react-redux";
import {fetchDataAbout} from "../store";
import global from "../data/global";
import Meta from "../components/Meta";
import PageLoader from "../components/PageLoader";

class About extends React.Component {
    state = {
        loading: true,
    };

    componentDidMount() {
        this.props.fetchDataAbout().then((res) => {
            this.setState({
                loading: false,
            });
        });
        window.scrollTo(0, 0);
    }

    render() {
        const {about, locale} = this.props;
        const {loading} = this.state;
        return (
            <Fragment>
                <PageLoader show={loading}/>
                <div>
                    <Meta
                        title={about?.title?.[locale]}
                        desc={about?.description?.[locale]}
                        img={about?.images?.[0]}
                        url={this.props.location.pathname}
                    />
                    <div className="container">
                        {/*<br/>*/}
                        {/*<div className="row">*/}
                        {/*<div className="col-md-12">*/}
                        {/*<h2 className="my-underline">*/}
                        {/*{global.about[locale]}*/}
                        {/*</h2>*/}
                        {/*</div>*/}
                        {/*</div>*/}
                        <div className="row d-flex align-items-center mb-50 pt-3 pt-md-5">
                            <div className="col-md-12">
                                <p className="float-title">
                                    <b
                                        dangerouslySetInnerHTML={{
                                            __html: about?.title?.[locale]
                                        }}
                                    />
                                </p>
                                <p
                                    className="desc"
                                    dangerouslySetInnerHTML={{
                                        __html: about?.description?.[locale]
                                    }}
                                />
                            </div>
                            <div className="col-md-12 mt-2 text-center">
                                <img
                                    style={{maxWidth: "100%"}}
                                    src={about?.images?.[0]}
                                    alt=""
                                />
                            </div>
                        </div>
                        {/*{about.data.descs.map((item, id) => (*/}
                        {/*<div className="row" key={id}>*/}
                        {/*<div className="col-md-12">*/}
                        {/*<p className="desc" dangerouslySetInnerHTML={{__html: item.txt[locale]}}/>*/}
                        {/*</div>*/}
                        {/*</div>*/}
                        {/*)*/}
                        {/*)}*/}
                        {/*<div className="row mt-50 mb-50">*/}
                        {/*<div className="col-md-12 ">*/}
                        {/*<p className="desc my-line"*/}
                        {/*dangerouslySetInnerHTML={{__html: about.data.info[locale]}}/>*/}
                        {/*</div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </Fragment>
        );
    }
}

About.serverFetch = fetchDataAbout;
const mapStateToProps = (state) => ({
    about: state.about,
});
const mapDispatchToProps = {
    fetchDataAbout,
};
export default connect(mapStateToProps, mapDispatchToProps)(About);
