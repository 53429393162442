import React from "react";
import { connect } from "react-redux";
import { fetchDataContact } from "../store";
import { withRouter } from "react-router";
import global from "../data/global";
import Form from "../components/Form";
import Meta from "../components/Meta";
import PageLoader from "../components/PageLoader";

class Contact extends React.Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    this.props.fetchDataContact().then((res) => {
      this.setState({
        loading: false,
      });
    });
    window.scrollTo(0, 0);
  }

  render() {
    const { locale, contact } = this.props;
    const { loading } = this.state;

    return (
      <div className="mb-100 mt-5">
        <PageLoader show={loading} />
        {contact && (
          <div className="container">
            <Meta
              titlec={contact.meta.title[locale]}
              desc={contact.meta.desc[locale]}
              img={contact.img}
              url={this.props.location.pathname}
            />
            <div className="row">
              <div className="col-md-12">
                <h2 className="my-underline">{global.contact_us[locale]}</h2>
              </div>
            </div>
            <div className="row d-flex justify-content-between">
              <div className="col-md-6">
                <Form locale={locale} />
              </div>
              <div className="col-md-4 text-center">
                <img src={contact.img} alt="" width="100%" />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

Contact.serverFetch = fetchDataContact;
const mapStateToProps = (state) => ({
  contact: state.contact.data,
});
const mapDispatchToProps = {
  fetchDataContact,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Contact)
);
