import React from "react";
// import Meta from "../components/Meta";
// import PageLoader from "../components/PageLoader";
import axios from "axios";
import {BASE_URL} from "../config/config";


class Terms extends React.Component {
    state = {
        termsText: ''
    }

    componentDidMount() {
        axios.get(`${BASE_URL}/terms_and_conditions`)
            .then(res => {
                this.setState({
                    termsText: res.data
                })
            })
    }

    render() {
        const {locale} = this.props;
        const {termsText} = this.state;

        return (
            <div className="mb-100 mt-5 container privacyText"
                 dangerouslySetInnerHTML={{__html: termsText?.text?.[locale]}}>
                {/*<h3>Little Armenias - Terms and Conditions</h3>
                <p className="fa-1x">
                    This website is provided by Little Armenias for personal use and
                    educational purposes only, with the express condition that users agree
                    to be bound by the terms and conditions set forth in this User
                    Agreement, as they may be modified from time to time. Your
                    consultation and use of this website constitutes your acknowledgment
                    and acceptance of all of the following terms and conditions. Little
                    Armenias makes no representations with respect to the information,
                    services, products or message on this website and specifically
                    disclaims any other warranties, including but not limited to implied
                    or express warranties of merchantability or fitness for any particular
                    usage, application or purpose. Little Armenias makes no warranties of
                    any kind regarding the operation of this website, including but not
                    limited to any warranty of accuracy, completeness, currency,
                    reliability, merchantability or fitness for a particular purpose, or
                    any warranty that these pages, or the server that makes them
                    available, are free of viruses, worms, Trojan horses, other harmful
                    elements or other code that manifest contaminating or destructive
                    properties and such warranties are expressly disclaimed.
                </p>
                <h4>Links from This Website</h4>
                <p className="fa-1x">
                    Little Armenias makes no representation, warranty or endorsement,
                    express or implied, as to any of the information, services, products
                    or messages on any other websites which may be accessed through a
                    hyperlink on this website. In particular, these links are provided for
                    convenience of reference only and are not intended as an endorsement
                    by Little Armenias of the organization or individual operating the
                    website or a warranty or endorsement of any type regarding the website
                    or the information, services, products or messages posted on or
                    available from such website.
                </p>
                <h4>Copyright and Trademark</h4>
                <p className="fa-1x">
                    Unless otherwise indicated, all information contained on this website,
                    such as text, graphics, logos, button icons, images, audio and video
                    clips, is copyrighted by and proprietary to Little Armenias. The
                    content displayed on any page of this website may be used for personal
                    and noncommercial uses which do not harm the reputation of Little
                    Armenias provided that the user does not remove any trademarks,
                    copyright and any other notice contained in such content. The name and
                    mark, Little Armenias and its attendant logos and taglines, are
                    trademarks of Little Armenias and may not be used or reproduced
                    without its prior written consent.
                </p>
                <h4>Liability</h4>
                <p className="fa-1x">
                    You agree that you will hold harmless Little Armenias and its
                    officers, directors, agents, employees, and volunteers from all claims
                    arising out of or related to your access or use of, or your inability
                    to access or use, this website or the information, services, products
                    or messages contained in this website or other websites to which it is
                    linked, including but not limited to claims that you have found
                    something you have heard, viewed or downloaded from this website or
                    another website to which it is linked to be obscene, offensive,
                    defamatory, or infringing upon your intellectual property rights. In
                    no event will Little Armenias or the contributors of information to
                    this website be liable to you or anyone else for any decision made or
                    action taken by you in reliance on such information or for any
                    consequential, special or similar damages, even if advised of the
                    possibility of such damages.
                </p>
                <h5>
                    YOU HEREBY RELEASE AND FOREVER WAIVE ANY AND ALL CLAIMS YOU MAY HAVE
                    AGAINST LITTLE ARMENIAS, ITS FOUNDER, ITS STAFF AND VOLUNTEERS FOR
                    LOSSES OR DAMAGES YOU SUSTAIN IN CONNECTION WITH YOUR USE OF THIS
                    WEBSITE.
                </h5>
                <h4>Linking from Other Websites</h4>
                <p className="fa-1x">
                    Little Armenias may grant the owner of a website permission to use a
                    hyperlink to this website from its website, provided: (a) any such
                    link must clearly be marked “Little Armenias;” (b) the appearance,
                    position and other aspects of either the link or the host website may
                    not be such as to damage or dilute the goodwill associated with Little
                    Armenias; name and trademarks; (c) the appearance, position and other
                    aspects of either the link or the host website may not create the
                    false appearance that any other entity is associated with or sponsored
                    by Little Armenias; and (d) the link, when activated by a user, must
                    display this website full-screen and not with a “frame” on the linked
                    website. The owner of any website with a hyperlink to this website
                    agrees to the foregoing terms and agrees to delete any such link upon
                    notice from Little Armenias that such permission has been revoked,
                    which notice may be given at any time and for any reason. Little
                    Armenias is not responsible for the information or materials contained
                    on websites linking to this website. Links to this website are
                    provided for convenience of reference only and are not intended as an
                    endorsement by Little Armenias of the organization or individual
                    operating the host website or a warranty of any type regarding the
                    host website or the information on the host website.
                </p>*/}
            </div>
        );
    }
}

export default Terms;
