import React, {Fragment} from "react";
import {connect} from "react-redux";
import {fetchData} from "../store";
import CityBlock from "../components/CityBlock";
import Slider from "../components/Slider";
import data from "../data/home";
import {withRouter} from "react-router";
// import WOW from "wow.js/dist/wow.js";
import Meta from "../components/Meta";
import global from "../data/global";
import {Link} from "react-router-dom";
import PageLoader from "../components/PageLoader";
import axios from "axios";

class Home extends React.Component {
    state = {
        loading: true,
        homeData: null,
    };

    componentDidMount() {
        this.props.fetchData().then((res) => {
            this.setState({
                loading: false,
            });
        });

        window.scrollTo(0, 0);

        axios.get('https://cms.littlearmenias.com/api/home')
            .then(res => {
                this.setState({
                    homeData: res.data.data
                })
            })
    }

    renderVideo = () => {
        return ` <video class="w-100" autoplay muted loop playsinline>
                <source src="/img/mobile_banner.mp4" type="video/mp4"/>
            </video>
           `;
    };

    render() {
        const {locale, cityList} = this.props;
        const {loading, homeData} = this.state;
        console.log(cityList);

        return (
            <Fragment>
                <PageLoader show={loading}/>
                <Meta
                    title={data.data.meta.title[locale]}
                    desc={data.data.meta.desc[locale]}
                    img={data.data.images[0].img}
                    url={this.props.location.pathname}
                />

                <div className="home-banner mt-10">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col d-none d-md-block">
                                {/*<img src="/img/map_new.svg" alt=""/>*/}
                                <div
                                    id="map-bg"
                                    className="img"
                                    style={{backgroundImage: "url(/img/map_new.png)"}}
                                >
                                    <div className="dot nth-7">
                                        <div
                                            className="dot-img"
                                            style={{
                                                backgroundImage:
                                                    "url(/img/city_icons/montebello_memorial.png)",
                                            }}
                                        />
                                        <span className="dot-text">{data.data.map[0][locale]}</span>
                                        <span className="dot-point orange"/>
                                    </div>

                                    <div className="dot nth-4">
                                        <div
                                            className="dot-img"
                                            style={{
                                                backgroundImage: "url(/img/city_icons/young_meher.png)",
                                            }}
                                        />
                                        <span className="dot-text">{data.data.map[1][locale]}</span>
                                        <span className="dot-point blue"/>
                                    </div>

                                    <div className="dot nth-1">
                                        <div
                                            className="dot-img"
                                            style={{
                                                backgroundImage:
                                                    "url(/img/city_icons/nyc_cathedral.png)",
                                            }}
                                        />
                                        <span className="dot-point orange"/>
                                    </div>

                                    <div className="dot nth-10">
                                        <div
                                            className="dot-img"
                                            style={{
                                                backgroundImage: "url(/img/city_icons/memorial.png)",
                                            }}
                                        />
                                        <span className="dot-text">{data.data.map[2][locale]}</span>
                                        <span className="dot-point red"/>
                                    </div>

                                    <div className="dot nth-6">
                                        <div
                                            className="dot-img"
                                            style={{
                                                backgroundImage:
                                                    "url(/img/city_icons/memorial_decines.png)",
                                            }}
                                        />
                                        <span className="dot-text">{data.data.map[3][locale]}</span>
                                        <span className="dot-point red"/>
                                    </div>

                                    <div className="dot nth-8">
                                        <div
                                            className="dot-img"
                                            style={{
                                                backgroundImage: "url(/img/city_icons/ethiopia.png)",
                                            }}
                                        />
                                        <span className="dot-text">{data.data.map[4][locale]}</span>
                                        <span className="dot-point orange"/>
                                    </div>

                                    <div className="dot nth-2">
                                        <div
                                            className="dot-img"
                                            style={{
                                                backgroundImage: "url(/img/city_icons/hindlyan.png)",
                                            }}
                                        />
                                        <span className="dot-point red"/>
                                    </div>

                                    {/*HEART*/}
                                    {/*<div className='dot'>*/}
                                    {/*    <div className='dot-img'*/}
                                    {/*         style={{backgroundImage: 'url(/img/city_icons/heart.png)'}}/>*/}
                                    {/*    <span className='dot-point red'/>*/}
                                    {/*</div>*/}
                                    {/*HEART*/}

                                    <div className="dot nth-5">
                                        <div
                                            className="dot-img"
                                            style={{
                                                backgroundImage: "url(/img/city_icons/church_1.png)",
                                            }}
                                        />
                                        <span className="dot-text">{data.data.map[5][locale]}</span>
                                        <span className="dot-point blue"/>
                                    </div>

                                    <div className="dot nth-9">
                                        <div
                                            className="dot-img"
                                            style={{
                                                backgroundImage: "url(/img/city_icons/new_julfa.png)",
                                            }}
                                        />
                                        <span className="dot-point blue"/>
                                    </div>

                                    <div className="dot nth-3">
                                        <div
                                            className="dot-img"
                                            style={{
                                                backgroundImage: "url(/img/city_icons/building_1.png)",
                                            }}
                                        />
                                        <span className="dot-text">{data.data.map[6][locale]}</span>
                                        <span className="dot-point blue"/>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col p-0 d-block d-md-none"
                                dangerouslySetInnerHTML={{__html: this.renderVideo()}}
                            ></div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mb-50">
                    <div className="row">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-11">
                                    <div className="flag-dots">
                                        <span className="red"/>
                                        <span className="blue"/>
                                        <span className="orange"/>
                                    </div>
                                    <h5
                                        className="home-banner-subtext"
                                        dangerouslySetInnerHTML={{
                                            __html: homeData?.banner_text?.[locale],
                                        }}
                                    />
                                    {/*<div className="home-banner-button">*/}
                                    {/*    <Link to={`/about?lang=${locale}`}>{global.see_more[locale]}</Link>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <CityBlock cityData={cityList && cityList} locale={locale}/>

                <div className="container-fluid bottom-section mt-5 mb-5">
                    <div className="row home">
                        <div className="socials">
                            <div className="social-box insta">
                                <br/>
                                <h3>#LittleArmenias</h3>
                                {/*<br />*/}
                                {/*<p>@littlearmenias</p>*/}
                                {/*<a href="https://www.instagram.com/littlearmenias/" rel='noreferrer noopener' target='_blank'>*/}
                                {/*<img src="/img/social_icons/instagram.svg" alt=""/>*/}
                                {/*<span>{global.follow[locale]}</span>*/}
                                {/*</a>*/}
                            </div>
                            <div className="social-box facebook">
                                {/*<p>{data.data.social_text[locale]}</p>*/}
                                <a
                                    href="https://www.instagram.com/littlearmenias/"
                                    rel="noreferrer noopener"
                                    className="instagram"
                                    target="_blank"
                                >
                                    <img src="/img/social_icons/instagram.svg" alt=""/>
                                </a>
                                <a
                                    href="https://www.facebook.com/littlearmenias/"
                                    rel="noreferrer noopener"
                                    className="facebook"
                                    target="_blank"
                                >
                                    <img src="/img/social_icons/facebook.svg" alt=""/>
                                </a>

                                {/*<a href="https://twitter.com/littlearmenias" rel='noreferrer noopener'*/}
                                {/*target='_blank'>*/}
                                {/*<img src="/img/social_icons/twitter.svg" alt=""/>*/}
                                {/*</a>*/}

                                <a
                                    href="https://www.youtube.com/channel/UCLoqBdTxEE0N0FUzmZ2WDlg"
                                    rel="noreferrer noopener"
                                    className="youtube"
                                    target="_blank"
                                >
                                    <img src="/img/social_icons/youtube.svg" alt=""/>
                                </a>

                                {/*<a href="https://www.facebook.com/littlearmenias/" rel='noreferrer noopener'*/}
                                {/*target='_blank'>*/}
                                {/*<img src="/img/social_icons/facebook.svg" alt=""/>*/}
                                {/*<span>Facebook</span>*/}
                                {/*</a>*/}
                            </div>
                        </div>
                        <div className="img-list" style={{display: "none"}}>
                            {/* <div className='img-list-body'>*/}
                            {/*<div className="img-wrapper">*/}
                            {/*<a href="https://www.instagram.com/littlearmenias/" rel='noreferrer noopener'*/}
                            {/*target='_blank'>*/}
                            {/*<div className='img'*/}
                            {/*style={{backgroundImage: 'url(/img/bottom_section/bottom_6.jpg)'}}/>*/}
                            {/*</a>*/}
                            {/*</div>*/}
                            {/*<div className="img-wrapper">*/}
                            {/*<a href="https://www.instagram.com/littlearmenias/" rel='noreferrer noopener'*/}
                            {/*target='_blank'>*/}
                            {/*<div className='img'*/}
                            {/*style={{backgroundImage: 'url(/img/bottom_section/bottom_2.jpg)'}}/>*/}
                            {/*</a>*/}
                            {/*</div>*/}
                            {/*<div className="img-wrapper">*/}
                            {/*<a href="https://www.instagram.com/littlearmenias/" rel='noreferrer noopener'*/}
                            {/*target='_blank'>*/}
                            {/*<div className='img'*/}
                            {/*style={{backgroundImage: 'url(/img/bottom_section/bottom_1.jpg)'}}/>*/}
                            {/*</a>*/}
                            {/*</div>*/}
                            {/*<div className="img-wrapper">*/}
                            {/*<a href="https://www.instagram.com/littlearmenias/" rel='noreferrer noopener'*/}
                            {/*target='_blank'>*/}
                            {/*<div className='img'*/}
                            {/*style={{backgroundImage: 'url(/img/bottom_section/bottom_3.jpg)'}}/>*/}
                            {/*</a>*/}
                            {/*</div>*/}
                            {/*<div className="img-wrapper">*/}
                            {/*<a href="https://www.instagram.com/littlearmenias/" rel='noreferrer noopener'*/}
                            {/*target='_blank'>*/}
                            {/*<div className='img'*/}
                            {/*style={{backgroundImage: 'url(/img/bottom_section/bottom_5.jpg)'}}/>*/}
                            {/*</a>*/}
                            {/*</div>*/}
                            {/*<div className="img-wrapper">*/}
                            {/*<a href="https://www.instagram.com/littlearmenias/" rel='noreferrer noopener'*/}
                            {/*target='_blank'>*/}
                            {/*<div className='img'*/}
                            {/*style={{backgroundImage: 'url(/img/bottom_section/bottom_4.jpg)'}}/>*/}
                            {/*</a>*/}
                            {/*</div>*/}
                            {/*</div> */}
                        </div>
                    </div>
                </div>

                <div className="container mt-100 mb-50">
                    <div className="row">
                        <div className="col-md-6">
                            <a
                                href={homeData?.guide_book_url}
                                rel="noreferrer noopener"
                                target="_blank"
                            >
                                <div className="book">
                                    <img
                                        className="book-front"
                                        src={homeData?.guide_book_front}
                                        alt=""
                                    />
                                    <img className="book-back"
                                         src={homeData?.guide_book_back}
                                         alt=""/>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-6">
                            <div className="book-text">
                                {/*<h3>{data.data.book.title[locale]}</h3>*/}
                                <p
                                    className="my-4"
                                    dangerouslySetInnerHTML={{
                                        __html: homeData?.guide_book_text[locale],
                                    }}
                                />
                                <a
                                    className="my-btn orange text-uppercase"
                                    href={homeData?.guide_book_url}
                                    rel="noreferrer noopener"
                                    target="_blank"
                                >
                                    {global.go_to_store[locale]}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<div className="container mb-150">*/}
                {/*    <div className="row">*/}
                {/*        <div className="col-md-12">*/}
                {/*            <h2 className="my-underline my-title">*/}
                {/*                Little Armenias*/}
                {/*            </h2>*/}
                {/*        </div>*/}
                {/*        <div className="col-md-12 mt-5">*/}
                {/*            <img src="/img/map_range.png" alt=""/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </Fragment>
        );
    }
}

Home.serverFetch = fetchData;
const mapStateToProps = (state) => ({
    cityList: state.cityList.data,
});
const mapDispatchToProps = {
    fetchData,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
