import "isomorphic-fetch";
import {BASE_URL, location} from "../config/config";

export function loadData() {
  return fetch(`${BASE_URL}/home_cities`).then((res) => {
    return res.json();
  });
  // .then(() => {
  //   setLoader(false);
  // });
}

export function loadCityData(citySlug) {
  return fetch(`${BASE_URL}/city/${citySlug}`).then((res) => {
    return res.json();
  });
}

export function loadActivityData(activitySlug) {
  return fetch(`${BASE_URL}/activity/${activitySlug}`).then((res) => {
    return res.json();
  });
}
