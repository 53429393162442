import React from "react";
import { Link, NavLink } from "react-router-dom";
import global from "../data/global";
import { fetchData } from "../store";
import { connect } from "react-redux";
import { SubscribeLoader } from "../components/BtnLoaders";
import {
  AvFeedback,
  AvForm,
  AvGroup,
  AvInput,
  AvField,
} from "availity-reactstrap-validation";
import { Button, Label } from "reactstrap";
import axios from "axios";

class FooterComponent extends React.Component {
  state = {
    disableBtn: false,
    msg: "",
  };

  componentDidMount() {
    this.props.fetchData();
  }

  onValidSubmit = (e, values) => {
    e.preventDefault();
    this.setState({ disableBtn: true, msg: "" });
    console.log(values);
    axios
      .post("https://api.littlearmenias.codics.solutions/api/subscribe", values)
      .then((res) => {
        this.setState({
          msg: "Sent",
          disableBtn: false,
        });
        document.getElementById("emailSubscribe").value = "";
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          msg: "Not Sent",
          disableBtn: false,
        });
      });
  };

  render() {
    const { locale } = this.props;
    const { disableBtn, msg } = this.state;
    return (
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-4 ord-1">
              <h4 />
              <ul className="footer-menu">
                {/*<li><a href="tel:123456789980">+123 456 789 980</a></li>*/}
                {/*<li><a href="mailto:love@littlearmenias.am">love@littlearmenias.am</a></li>*/}
                <li>
                  <Link to={`/privacy_policy/?lang=${locale}`}>
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to={`/terms/?lang=${locale}`}>Terms & Conditions</Link>
                </li>
                {/*<li>Yerevan, tbilisi str. #112</li>*/}
              </ul>
            </div>
            <div className="col-md-4 ord-2">
              <h4>{global.follow_us[locale]}</h4>
              <div className="d-flex social-footer">
                <a
                  href="https://www.instagram.com/littlearmenias/"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <span className="social-link inst">
                    <img src="/img/icons/instagram.svg" alt="" />
                  </span>
                </a>

                <a
                  href="https://www.facebook.com/littlearmenias/"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <span className="social-link fb">
                    <img src="/img/icons/Facebook_.svg" alt="" />
                  </span>
                </a>

                <a
                  href="https://www.youtube.com/channel/UCLoqBdTxEE0N0FUzmZ2WDlg"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <span className="social-link yt">
                    <img src="/img/icons/youtube.svg" alt="" />
                  </span>
                </a>
              </div>
            </div>
            <div className="col-md-4 ord-3">
              <div className="row justify-content-center">
                <div className="col-md-9">
                  <AvForm
                    className="my-form subscribe"
                    onValidSubmit={this.onValidSubmit}
                  >
                    <h4 style={{ textAlign: "left" }}>
                      {global.subscribe_newsletter[locale]}
                    </h4>
                    <div className="d-flex">
                      <AvField
                        id="emailSubscribe"
                        type="email"
                        name="email"
                        placeholder={global.email[locale]}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter an Email",
                          },
                          email: {
                            value: true,
                            errorMessage: "This is not a valid email address.",
                          },
                        }}
                      />
                      <button
                        disabled={disableBtn}
                        type="submit"
                        className="my-btn orange"
                      >
                        {disableBtn ? (
                          <SubscribeLoader />
                        ) : (
                          <img src="/img/icons/arrow_right.svg" alt="" />
                        )}
                      </button>
                    </div>
                  </AvForm>
                  <p className="mt-2">
                    <small>{msg}</small>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-4 text-center ord-4">
              <span>
                <small>Copyright © {new Date().getFullYear()} Little Armenias</small>
              </span>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

FooterComponent.serverFetch = fetchData;
const mapStateToProps = (state) => ({
  cityList: state.cityList.data,
});
const mapDispatchToProps = {
  fetchData,
};
export default connect(mapStateToProps, mapDispatchToProps)(FooterComponent);
