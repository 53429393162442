import React, { Component } from "react";
import FadeInBlocks from "./FadeInBlocks";
import { NavLink, withRouter } from "react-router-dom";
import global from "../data/global";
class ActivityBlock extends Component {
  render() {
    const { data, locale } = this.props;
    return (
      <div className="container mb-50">
        <div className="row">
          <div className="col-md-12">
            <h2 className="my-underline my-title">
              {global.activities[locale]}
            </h2>
          </div>
        </div>
        <div className="row">
          {data &&
            data.map((item, index) => (
              <FadeInBlocks key={index} delay={300}>
                <NavLink
                  to={`${this.props.match.params.slag}/${item.slug}?lang=${locale}`}
                >
                  <div className="my-card activity">
                    <div
                      style={{ backgroundImage: `url(${item.small_image})` }}
                      className="my-card-img"
                    />
                  </div>
                  <div className="my-card-info">
                    {item?.type?.[locale] && (
                      <>
                        <span>{item.type[locale].toUpperCase()}</span>
                        <br />
                      </>
                    )}
                    <p
                      dangerouslySetInnerHTML={{ __html: item.title[locale] }}
                    />
                  </div>
                </NavLink>
              </FadeInBlocks>
            ))}
        </div>
      </div>
    );
  }
}

export default withRouter(ActivityBlock);
