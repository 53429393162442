import React, { Component, useRef, useState, useEffect } from "react";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import global from "../data/global";
import Swiper from "react-id-swiper";

const PhotoItem = ({ item, locale }) => {
  const [conditionalRender, setConditionalRender] = useState(false);

  useEffect(() => {
    new Promise((resolve) => {
      setConditionalRender(false);
      resolve(1);
    }).then((res) => {
      res && setConditionalRender(true);
    });
  }, [locale]);

  return conditionalRender ? (
    <LightgalleryItem
      src={item.image}
      subHtml={`<p style="font-size:1.3rem;font-weight:500;">${
        item.text && item.text[locale]
      }</p>`}
      group={"group1"}
    >
      <img className="lightgallery-zoom" src="/img/icons/zoom.png" alt="" />
      <img className="lightgallery-thumb" src={item.image} />
    </LightgalleryItem>
  ) : (
    ""
  );
};

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.swiperMain = null;
  }

  swiperInit = (props) => {
    this.swiperMain = props;
    const { swiperMain } = this;
    if (swiperMain !== null) {
      swiperMain.init();
    }
  };

  render() {
    const { data, locale } = this.props;
    const params = {
      slidersPerView: 1,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      getSwiper: this.swiperInit,
    };
    return (
      <div className="container mb-50">
        <div className="row">
          <div className="col-md-12">
            <h2 className="my-underline my-title">{global.gallery[locale]}</h2>
          </div>
        </div>
        <div className="row desktop-el">
          <LightgalleryProvider
            onAfterSlide={() => {
              console.log("onAfterSlide");
            }}
          >
            {data?.map((p, idx) =>
                  <div key={idx} className="col-md-3 py-0">
                    <PhotoItem item={p} locale={locale} />
                  </div>
            )}
          </LightgalleryProvider>
        </div>
        <div className="lightgallery-slider row mobile-el">
          <div className="col-md-12">
            {data && (
              <Swiper {...params}>
                {data.map((item, idx) => (
                  <div key={idx}>
                    <div style={{ backgroundImage: `url(${item.image})` }}></div>
                    <p>{item?.text?.[locale]}</p>
                  </div>
                ))}
              </Swiper>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Gallery;
