import React, { Fragment } from "react";
import { connect } from "react-redux";
import ActivityBlock from "../components/ActivityBlock";
import Gallery from "../components/Gallery";
import { fetchCityData } from "../store";
import Meta from "../components/Meta";
import PageLoader from "../components/PageLoader";
import axios from "axios";

class CityInner extends React.Component {
  state = {
    loaded: false,
    bannerLoaded: false,
    activities: null,
  };

  cityBanner = React.createRef();

  componentDidMount() {
    const slug = this.props.match.params.slag;
    this.props.fetchCityData(slug).then((res) => {
      this.setState({
        loaded: true,
      });
    });
    this.cityBanner.current?.complete && this.setState({ bannerLoaded: true });
    window.scrollTo(0, 0);

    axios
      .get(`https://cms.littlearmenias.com/api/city/${slug}/activities`)
      .then((res) => {
        this.setState({
          activities: res.data.data,
        });
      });
  }

  render() {
    const { currentCity, locale } = this.props;
    const { loaded, bannerLoaded, activities } = this.state;
    // const currentData =
    //   city &&
    //   city.filter((obj) => {
    //     return obj.slug === this.props.match.params.slag;
    //   });
    // let stringArr = currentData?.[0].banner.split("/");
    // let fileName = stringArr?.pop()?.replace(/\.(jpg|jpeg|JPG)$/, ".png");
    // const downloadLink = `${stringArr?.join("/")}/for_download/${fileName}`;

    // console.log(currentData)
    return (
      <Fragment>
        <PageLoader show={!(bannerLoaded && loaded)} />
        {currentCity && (
          <div>
            <Meta
              title={currentCity?.title?.[locale]}
              desc={currentCity?.description?.[locale]}
              img={currentCity?.banner_image}
              url={this.props.location.pathname}
            />
            <div className="container mt-5">
              <div className="row">
                <div className="col-md-12">
                  <h2
                    className="my-underline"
                    dangerouslySetInnerHTML={{
                      __html: currentCity?.title?.[locale],
                    }}
                  />
                </div>
                <div className="col-md-12 mt-5 mb-5">
                  {currentCity?.banner_image && (
                    <div className="col-md-12 px-0 mb-4 relative">
                      <img
                        ref={this.cityBanner}
                        className="content-image"
                        src={currentCity.banner_image}
                        alt=""
                        onLoad={() => {
                          this.setState({ bannerLoaded: true });
                        }}
                      />
                      <a
                        href={
                          currentCity.hq_banner_image ||
                          currentCity.banner_image
                        }
                        download
                        target={"_blank"}
                        rel="noreferrer noopener"
                        className="banner-download-btn"
                      >
                        Download
                      </a>
                    </div>
                  )}
                  <p
                    className="desc"
                    dangerouslySetInnerHTML={{
                      __html: currentCity?.description?.[locale],
                    }}
                  />
                </div>
              </div>
            </div>

            {activities && <ActivityBlock data={activities} locale={locale} />}
            {currentCity?.gallery && (
              <Gallery data={currentCity.gallery} locale={locale} />
            )}
          </div>
        )}
      </Fragment>
    );
  }
}

CityInner.serverFetch = fetchCityData;
const mapStateToProps = (state) => ({
  currentCity: state.cityData.data,
});
const mapDispatchToProps = {
  fetchCityData,
};
export default connect(mapStateToProps, mapDispatchToProps)(CityInner);
