import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import global from "../data/global";
import i18n from "../locale/i18n";

class HeaderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      targetWith: null,
      targetLeft: null,
      showMenu: false,
    };
  }

  componentDidMount() {
    i18n.on("languageChanged", function (lng) {
      setTimeout(() => {
        document.getElementById("header-line").style.width =
          document.querySelector(".active.myNav-link") &&
          document.querySelector(".active.myNav-link").clientWidth - 30 + "px";
        document.getElementById("header-line").style.transform = `translateX(${
          document.querySelector(".active.myNav-link") &&
          document.querySelector(".active.myNav-link").getBoundingClientRect()
            .left + 15
        }px)`;
      }, 1);
    });
    setTimeout(() => {
      this.setState({
        targetWidth:
          document.querySelector(".active.myNav-link") &&
          document.querySelector(".active.myNav-link").clientWidth - 30,
        targetLeft:
          document.querySelector(".active.myNav-link") &&
          document.querySelector(".active.myNav-link").getBoundingClientRect()
            .left + 15,
      });
    }, 500);
    setTimeout(() => {
      document.querySelector("header hr").style.opacity = 1;
    }, 1000);
  }

  onMouseEnter = (e) => {
    this.setState({
      targetWidth: e.target.getBoundingClientRect().width - 30,
      targetLeft: e.target.getBoundingClientRect().left + 15,
    });
  };

  mouseOut = (e) => {
    this.setState({
      targetWidth: document.querySelector(".active.myNav-link")
        ? document.querySelector(".active.myNav-link").clientWidth - 30
        : 0,
      targetLeft:
        document.querySelector(".active.myNav-link") &&
        document.querySelector(".active.myNav-link").getBoundingClientRect()
          .left + 15,
    });
  };

  routeChange = (e, dataHref) => {
    this.props.history.push(dataHref);

    setTimeout(() => {
      this.setState({
        showMenu: false,
      });
    }, 400);
  };

  render() {
    const { currentLang } = this.props;
    return (
      <header className="header" onMouseLeave={(e) => this.mouseOut(e)}>
        <div className="container">
          <nav className="row">
            <div className="d-flex w-100 justify-content-between align-items-center">
              <div className="">
                <Link to={`/?lang=${currentLang}`}>
                  <img src="/img/logo.png" alt="" className="logo" />
                  <img
                    src="/img/logo_mobile.png"
                    alt=""
                    className="logo mobile"
                  />
                </Link>
              </div>
              <div
                id="desktop-part"
                className="d-flex justify-content-between align-items-center"
              >
                <div className="header-link">
                  <ul className="d-flex w-100 justify-content-between">
                    {/*<li className={this.props.location.pathname === '/' ? 'active myNav-link' : 'myNav-link'}*/}
                    {/*onMouseEnter={e => this.onMouseEnter(e)}>*/}
                    {/*<Link*/}
                    {/*to={`/?lang=${currentLang}`}>{global.home[currentLang]}</Link>*/}
                    {/*</li>*/}
                    <li
                      className={
                        this.props.location.pathname === "/who_are_we"
                          ? "active myNav-link"
                          : "myNav-link"
                      }
                      onMouseEnter={(e) => this.onMouseEnter(e)}
                    >
                      <Link to={`/who_are_we?lang=${currentLang}`}>
                        {global.about[currentLang]}
                      </Link>
                    </li>
                    <li
                      className={
                        this.props.location.pathname === "/get_in_touch"
                          ? "active myNav-link"
                          : "myNav-link"
                      }
                      onMouseEnter={(e) => this.onMouseEnter(e)}
                    >
                      <Link to={`/get_in_touch?lang=${currentLang}`}>
                        {global.contact_us[currentLang]}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="lang-dropdown-wrapper">
                  <div className="lang-btn d-flex justify-content-between align-items-center">
                    <span>
                      {(currentLang === "en" && "EN") ||
                        (currentLang === "ru" && "RU") ||
                        (currentLang === "fr" && "FR") ||
                        "EN"}
                    </span>
                    <div className="active-flag">
                      <img
                        className="w-100"
                        src={`/img/icons/${
                          (currentLang === "en" && "united-states.svg") ||
                          (currentLang === "ru" && "russia.svg") ||
                          (currentLang === "fr" && "france.svg")
                        }`}
                        alt=""
                      />
                    </div>

                    {/*<div className="lang-down-arrow">*/}
                    {/*<img src="/img/icons/arrow_down.svg" alt=""/>*/}
                    {/*</div>*/}
                  </div>
                  <div className="lang-block">
                    <ul>
                      <li
                        style={{
                          display: currentLang !== "en" ? "block" : "none",
                        }}
                      >
                        <div onClick={() => this.props.onClick("en", "EN")}>
                          <img src="/img/icons/united-states.svg" alt="" />
                          <span>ENG</span>
                        </div>
                      </li>
                      <li
                        style={{
                          display: currentLang !== "fr" ? "block" : "none",
                        }}
                      >
                        <div onClick={() => this.props.onClick("fr", "FR")}>
                          <img src="/img/icons/france.svg" alt="" />
                          <span>FRA</span>
                        </div>
                      </li>
                      <li
                        style={{
                          display: currentLang !== "ru" ? "block" : "none",
                        }}
                      >
                        <div onClick={() => this.props.onClick("ru", "RU")}>
                          <img src="/img/icons/russia.svg" alt="" />
                          <span>RUS</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="menu"
                onClick={() =>
                  this.setState({ showMenu: !this.state.showMenu })
                }
              >
                <div className="myContainer">
                  <div className="myMenu">
                    <div className="burger" id="burger">
                      <div className="topBurger" />
                      <div className="bottomBurger" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={this.state.showMenu ? "menuPage show" : "menuPage"}
                id="menuPage"
              >
                <ul className="row infoContent">
                  <li className="mb-4">
                    <button
                      onClick={(e) =>
                        this.routeChange(e, `/who_are_we?lang=${currentLang}`)
                      }
                    >
                      {global.about[currentLang]}
                    </button>
                  </li>
                  <li className="mb-4 offset-md-1">
                    <button
                      onClick={(e) =>
                        this.routeChange(e, `/get_in_touch?lang=${currentLang}`)
                      }
                    >
                      {global.contact_us[currentLang]}
                    </button>
                  </li>
                  <li className="">
                    <ul className="lng-bar">
                      <li className={currentLang === "en" ? "active" : ""}>
                        <div onClick={() => this.props.onClick("en", "EN")}>
                          <img src="/img/icons/united-states.svg" alt="" />
                          <span>ENG</span>
                        </div>
                      </li>
                      <li className={currentLang === "fr" ? "active" : ""}>
                        <div onClick={() => this.props.onClick("fr", "FR")}>
                          <img src="/img/icons/france.svg" alt="" />
                          <span>FRA</span>
                        </div>
                      </li>
                      <li className={currentLang === "ru" ? "active" : ""}>
                        <div onClick={() => this.props.onClick("ru", "RU")}>
                          <img src="/img/icons/russia.svg" alt="" />
                          <span>RUS</span>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
        <hr
          id="header-line"
          style={{
            width: this.state.targetWidth + "px",
            transform: `translateX(${this.state.targetLeft}px)`,
          }}
        />
      </header>
    );
  }
}

export default withRouter(HeaderComponent);
