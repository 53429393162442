import React from 'react';
import Helmet from "react-helmet";
import {location} from "../config/config"
class Meta extends React.Component {
    render(){
        const {title, desc , img, keywords,url} = this.props
        return(
            <Helmet>
                <title>{title}</title>
                <meta name="keywords" content={keywords}/>
                <meta name="description" content={desc}/>
                <meta name="og:title" content={title}/>
                <meta name="og:url" content={`${location}${url}`}/>
                <meta name="og:image" content={img}/>
                <meta name="og:site_name" content="Little Armenias"/>
                <meta name="og:description" content={desc}/>
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:image:width" content="400" />
                <meta property="og:image:height" content="300" />
            </Helmet>
        )
    }
}
export default Meta;
