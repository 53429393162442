import React, {Component} from 'react';

const FadeInBlocks = (props, {delay}) => {

    return (
        <div className="col-md-4 my-fadeInBlock">
            <div className="customFadeInUp  wow" data-wow-delay={delay}>
                {props.children}
            </div>
        </div>
    );
}
export default FadeInBlocks;
