import React from "react";
// import Meta from "../components/Meta";
// import PageLoader from "../components/PageLoader";
import axios from "axios";
import {BASE_URL} from "../config/config";


class Privacy extends React.Component {
    state = {
        privacyText: ''
    }

    componentDidMount() {
        axios.get(`${BASE_URL}/privacy_policies`)
            .then(res => {
                this.setState({
                    privacyText: res.data
                })
            })
    }

    render() {
        const {locale} = this.props;
        const {privacyText} = this.state;
        return (
            <div className="mb-100 mt-5 container privacyText"
                 dangerouslySetInnerHTML={{__html: privacyText?.text?.[locale]}}>
                {/*<h3>Little Armenias – Privacy Policy</h3>
                <p className="fa-1x">
                    Little Armenias is committed to safeguarding your privacy. This
                    statement explains our online information practices and the choices
                    you can make about the way the information you provide us is collected
                    and used. Little Armenias is the sole owner of and the only
                    organization that uses the personal information collected on this
                    site. We will not sell or rent this information to others under any
                    circumstances. This Privacy Policy applies to all information received
                    by Little Armenias, both online and offline, as well as any
                    electronic, written or oral communication.
                </p>
                <p className="fa-1x">
                    This Policy may be changed or updated from time to time. For easy and
                    direct access to the most up to date terms you may find a link to this
                    statement from our home page and all other pages of our website.
                </p>
                <h4>Security</h4>
                <p className="fa-1x">
                    We care about the security of your transactions and apply
                    industry-standard practices and technologies to safeguard your credit
                    card information. We use high-grade encryption and the secure https
                    protocol to communicate with your browser software, which guards
                    against interception of the credit card information you give us. We
                    also employ several different security techniques to protect your
                    personally identifiable information from unauthorized access by users
                    inside and outside the organization. The web servers for Little
                    Armenias are located in a locked, secure environment, and computer
                    systems are maintained in accordance with industry standards to secure
                    information. You should be aware, however, that “perfect security”
                    does not exist on the Internet, and third parties may unlawfully
                    intercept or access transmissions or private communications.
                </p>
                <h4>Mailing List</h4>
                <p className="fa-1x">
                    Little Armenias provides an online form to join our mailing list. You
                    may also request to be added to our list by voluntarily sending an
                    email directly to an Little Armenias employee actively requesting to
                    be included in our list.
                </p>
                <p className="fa-1x">
                    The information we collect includes: name, address, e-mail address and
                    phone number. You may also submit alumni and personal information.
                    This information is securely stored internally. Little Armenias does
                    not sell, trade or share any personally identifiable information with
                    any non-Little Armenias entity. In addition, we do not send mailings
                    on behalf of other organizations.
                </p>
                <p className="fa-1x">
                    Please contact us directly by regular mail if at any time you wish to
                    be removed from the mailing list or if you would like to correct or
                    change the personal information you have previously provided.
                </p>
                <h4>Social Media Community Guidelines</h4>
                <p className="fa-1x">
                    Little Armenias social media channels provide a place where ideas and
                    content can be shared, and conversation can flow freely and
                    respectfully. We encourage you to join the conversation. Below are our
                    guidelines for communicating on these channels.
                </p>
                <p className="fa-1x">
                    <ul className="privacy_list">
                        <li>Please be respectful when interacting with our community.</li>
                        <li>
                            We do not tolerate abusive language or content, including material
                            that is defamatory, obscene, violent, threatening, harassing,
                            discriminatory, aggressive, argumentative or that can be construed
                            as bullying or is otherwise deemed inappropriate.
                        </li>
                        <li>
                            We reserve the right to remove content from our social media
                            channels if we feel it fails to adhere to any of these guidelines.
                        </li>
                        <li>
                            We reserve the right to block/ban users from our social media
                            channels if they exhibit inappropriate behavior
                        </li>
                    </ul>
                </p>

                <h4>Links to Other Websites</h4>
                <p className="fa-1x">
                    Little Armenias’ site includes links, some temporary and some
                    permanent, to other websites. We are not responsible for the privacy
                    and security practices of any other website or the nature of the
                    content contained therein.
                </p>
                <h4>Use of Text and Images</h4>
                <p className="fa-1x">
                    If you would like to publish information that you find on our Website,
                    please send your request to{" "}
                    <a className="text-primary" href="littlearmenias@gmail.com">
                        littlearmenias@gmail.com
                    </a>
                </p>

                <h4>“Cookies”</h4>
                <p className="fa-1x">
                    This site uses cookies. By using this site, you agree that Little
                    Armenias may place cookies on your device and access them when you
                    visit our site. A cookie is a small file of letters and numbers that
                    we put on your computer. The cookies we use aid us in improving our
                    site and your experience by tracking which areas and features of our
                    site are popular, making the site easier to navigate, count visits,
                    and allow a registered user to remain signed in to his/her user
                    profile throughout a visit. We may also use cookies placed on our site
                    by third parties acting on our behalf. If you want to opt out of our
                    cookies, you can delete them and any other cookies that are already on
                    your computer. Please refer to the help and support areas on your
                    Internet browser for instructions on how to locate the file or
                    directory that stores cookies. Some functionalities on our site may be
                    affected if you delete our cookies.
                </p>

                <h4>Changes to This Policy</h4>
                <p className="fa-1x">
                    In the event that Little Armenias decides to alter its privacy policy,
                    the changes will be added directly to this statement.
                </p>

                <h4>Contact Us</h4>
                <p className="fa-1x">
                    If you have specific questions or concerns about Little Armenias’
                    privacy policy, please contact us at{" "}
                    <a className="text-primary" href="littlearmenias@gmail.com">
                        littlearmenias@gmail.com
                    </a>
                </p>*/}
            </div>
        );
    }
}

export default Privacy;
