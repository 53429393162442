import React, {Component, Fragment} from "react";
import {Switch, withRouter} from "react-router-dom";
import {renderRoutes} from "react-router-config";
import Routes from "./routes";
import queryString from "query-string";
import HeaderComponent from "./components/Header";
import i18n from "./locale/i18n";
import FooterComponent from "./components/Footer";
import ReactGA from 'react-ga';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentLang: queryString.parse(this.props.location.search).lang || "en",
            data: [],
        };
    }

    handleClick = (lang) => {
        this.setState({
            currentLang: lang,
        });
        i18n.changeLanguage(lang);
        this.props.history.push({
            search: `?lang=${lang}`,
        });
    };

    GAInit = () => {
        ReactGA.initialize('G-FJH54QWK2K');
        ReactGA.pageview(this.props.location.pathname + this.props.location.search);
    }

    componentDidMount() {
        this.GAInit();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.GAInit();
        }
    }

    render() {
        const {currentLang} = this.state;
        return (
            <Fragment>
                <HeaderComponent
                    onClick={(e) => this.handleClick(e)}
                    currentLang={currentLang}
                />
                <main>
                    <Switch>{renderRoutes(Routes, {locale: currentLang})}</Switch>
                </main>
                <FooterComponent locale={currentLang}/>
            </Fragment>
        );
    }
}

export default withRouter(App);
