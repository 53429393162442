import React from "react";

export const BtnLoader = () => {
  return (
    <div className="btn-loader">
      <div className="lds-grid">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export const SubscribeLoader = () => {
  return (
    <div className="subscribe-loader">
      <div class="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
